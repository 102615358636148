<script>
  import Header from "../../components/Header.svelte";
  import { _, init } from "svelte-i18n";

  let language = localStorage.getItem("enrol-app-locale") || "en";

  init({
    fallbackLocale: language,
    initialLocale: language,
  });
</script>

<Header title={$_("success.Thank You") + "!"} />

<main>
  <slot />
</main>
